import { gql, useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gameStateLoaded } from '../../Actions'
import { GamePageState } from '../../Store'
import { GameData, GameNotification } from '../../Types'
import { AVATAR_INFO, NOTIFICATIONS, PLAYERS_TIME } from './GameClientAPI'
import { GameNotificationsListener } from './GameNotificationsListener'

const GET_GAME = gql`
  query Game($id: String!) {
    time
    gameNotifications(gameId: $id, start: 0) { ...Notifications }
    game(id: $id) {
      id
      players {
        id userId name avatar {...AvatarInfo} quit quitReason gamePointsDelta tournamentPoints ...PlayerTime
      }
      options {
        speed maxSpareTime maxExceedTime
      }
      notificationsChannel mode setup state myPlayerId endDate
      tournament {
        number signedUp
      }
      version
    }
  } ${PLAYERS_TIME} ${AVATAR_INFO} ${NOTIFICATIONS}
`

export function RemoteGameLoader({ gameId, version = 1 }: { gameId: string, version?: number }) {
  const dispatch = useDispatch()
  const isOver = useSelector((state: GamePageState) => state.gameOver)
  const [isOverForLongEnough, setIsOverForLongEnough] = useState(isOver)
  useEffect(() => {
    if (isOver) {
      setTimeout(() => setIsOverForLongEnough(isOver), 10000)
    }
  }, [isOver])
  const [getGame, { data }] = useLazyQuery<{ game: GameData, time: number, gameNotifications: GameNotification[] }>(GET_GAME)
  useEffect(() => {
    getGame({ variables: { id: gameId } }).then(result => {
      if (result.data) {
        const { game, time, gameNotifications } = result.data
        const remoteVersion = game.version
        if (remoteVersion && remoteVersion !== version) {
          const url = new URL(window.location.href)
          const path = url.hostname.split('.')
          url.hostname = `${path[0]}.v${remoteVersion}.${path[path.length - 2]}.${path[path.length - 1]}`
          window.location.href = url.href
        } else {
          dispatch(gameStateLoaded({ ...game, notifications: gameNotifications }, time))
        }
      }
    })
  }, [])
  if (!data || data.game.endDate || isOverForLongEnough) return null
  else return <GameNotificationsListener channelName={data?.game?.notificationsChannel ?? `game=${gameId}`} gameId={gameId}
                                         notificationsCount={data.gameNotifications.length}/>
}