import { PusherProvider } from '@harelpls/use-pusher'
import Pusher from 'pusher-js'
import Ajax from 'pusher-js/types/src/core/http/ajax'
import { PropsWithChildren, useMemo } from 'react'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { createAnimationsListener } from '../../animations'
import { PLATFORM_URI } from '../../index'
import { gameMiddleware } from '../../Store/gameMiddleware'
import { gameReducer, GameReducerContext } from '../../Store/gameReducer'
import { localMovePreviewMiddleware } from '../../Store/localMovePreviewMiddleware'
import { GameClientAPI } from './GameClientAPI'
import { RemoteGameLoader } from './RemoteGameLoader'

Pusher.logToConsole = true

Pusher.Runtime.createXHR = function () {
  const xhr = new XMLHttpRequest() as Ajax
  xhr.withCredentials = true
  return xhr
}

const authEndpoint = PLATFORM_URI + (process.env.REACT_APP_PUSHER_ENDPOINT ?? '/api/pusher/auth')
const clientKey = process.env.REACT_APP_PUSHER_KEY ?? '747557f1e8edf7fc2816'
const cluster = process.env.REACT_APP_PUSHER_CLUSTER ?? 'eu'

type RemoveGameProviderProps<Game = any, Move = any, PlayerId = any> = {
  gameId: string
} & GameReducerContext<Game, Move, PlayerId>

export function RemoteGameProvider<Game = any, Move = any, PlayerId = any>(
  { gameId, children, ...context }: PropsWithChildren<RemoveGameProviderProps<Game, Move, PlayerId>>
) {
  const store = useMemo(() => createRemoteApiStore(gameId, context), [])
  return (
    <PusherProvider authEndpoint={authEndpoint} clientKey={clientKey} cluster={cluster}>
      <Provider store={store}>
        <RemoteGameLoader gameId={gameId} version={context.version}/>
        {children}
      </Provider>
    </PusherProvider>
  )
}

function createRemoteApiStore<Game = any, Move = any, PlayerId = any>(
  gameId: string, context: GameReducerContext<Game, Move, PlayerId>
) {
  const api = new GameClientAPI(gameId)
  const reducer = gameReducer(context)
  const store = createStore(reducer, applyMiddleware(localMovePreviewMiddleware(context), gameMiddleware(api)))
  store.subscribe(createAnimationsListener(store, context.Rules))
  api.init(store.dispatch)
  return store
}
