import { SVGProps } from 'react'

export const MedalSilver = (props: SVGProps<SVGSVGElement>) => (
  <svg width="38px" height="49px" viewBox="0 0 38 49" {...props}>
    <g transform="translate(10.450000, 10.238806)" fill="#6F7F8F">
      <polygon transform="translate(16.028352, 2.079550) rotate(-82.007686) translate(-16.028352, -2.079550)"
               points="14.0575296 1.36831678 17.9999857 1.36608248 17.9991751 2.7907837 14.0567189 2.793018"/>
      <polygon transform="translate(2.034853, 2.198270) rotate(-7.992314) translate(-2.034853, -2.198270)"
               points="1.32369911 0.0874146876 2.74840033 0.0866040631 2.74600731 4.30912484 1.32130609 4.30993547"/>
    </g>
    <path fill="#E8F4F9"
          d="M37.05,30.7159422 C37.05,35.7593281 34.9711154,40.3317697 31.6138807,43.6426512 C28.2595359,46.9516299 23.6306942,49 18.525,49 C18.1695281,49 17.8169462,48.990486 17.466291,48.9705065 C12.7815757,48.7088708 8.55733619,46.7213904 5.43611934,43.6426512 C2.07888456,40.3317697 0,35.7593281 0,30.7159422 C0,25.6754105 2.07792122,21.1048718 5.43226599,17.7939902 C8.55348284,14.7133482 12.7786856,12.7239651 17.466291,12.4623293 C17.8169462,12.4423498 18.1695281,12.4328358 18.525,12.4328358 C23.6326209,12.4328358 28.2633892,14.4831087 31.617734,17.7939902 C34.9720788,21.1048718 37.05,25.6754105 37.05,30.7159422 Z"/>
    <g transform="translate(5.700000, 12.432836)" fill="#D4E3E8">
      <path
        d="M26.6921702,5.41383989 L25.5759058,6.49375848 L13.3539392,18.3187246 L13.3490304,18.3225237 L0.00589057751,5.41383989 C3.18680243,2.33839891 7.49281459,0.352374491 12.2700729,0.0911804613 C12.6274347,0.0712347354 12.9867599,0.0617367707 13.3490304,0.0617367707 C18.5543374,0.0617367707 23.2736717,2.10854817 26.6921702,5.41383989 Z"/>
      <path
        d="M26.6882432,31.2188602 C23.2697447,34.5222524 18.5523739,36.5671642 13.3490304,36.5671642 C12.9867599,36.5671642 12.6274347,36.5576662 12.2700729,36.5377205 C7.49575988,36.2765265 3.19072948,34.2924016 0.00981762918,31.2188602 L13.3451033,18.3187246 L13.3490304,18.3139756 L26.6882432,31.2188602 Z"/>
      <path
        d="M32.2283313,18.3139756 C32.2283313,23.3488467 30.1096869,27.9135685 26.6882432,31.2188602 C23.2697447,34.5222524 18.5523739,36.5671642 13.3490304,36.5671642 C12.9867599,36.5671642 12.6274347,36.5576662 12.2700729,36.5377205 C22.1937325,35.9963365 30.0684529,28.0446404 30.0684529,18.3139756 C30.0684529,8.58426052 22.1937325,0.63256445 12.2700729,0.0911804613 C12.6274347,0.0712347354 12.9867599,0.0617367707 13.3490304,0.0617367707 C18.5543374,0.0617367707 23.2736717,2.10854817 26.6921702,5.41383989 C30.1106687,8.71913161 32.2283313,13.2819539 32.2283313,18.3139756 Z"/>
    </g>
    <path fill="#B8CDD3"
          d="M31.1967554,42.4179104 L32.3,43.5236782 C28.9213741,46.9061408 24.2590256,49 19.1163727,49 C18.7583277,49 18.4031935,48.9902747 18.05,48.9698515 C23.3110309,48.6722571 27.9889044,46.1913306 31.1967554,42.4179104 Z"/>
    <path fill="#B8CDD3"
          d="M18.05,12.4629665 C18.4030973,12.4425554 18.7581348,12.4328358 19.1160824,12.4328358 C24.259275,12.4328358 28.9222941,14.5274021 32.3,17.9098108 L31.1970558,19.0149254 C27.9900783,15.2408182 23.3115385,12.7603852 18.05,12.4629665 Z"/>
    <path
      d="M37.8868896,0.797108564 L30.0454405,10.238806 L14.25,10.238806 L22.605788,0.179081777 C22.6995735,0.0652091255 22.839758,0 22.9888275,0 L37.5048373,0 C37.9224293,0 38.1514632,0.478849101 37.8868896,0.797108564 Z"/>
    <path
      d="M37.8918474,0.797108564 L30.3941024,10.238806 L27.55,10.238806 L35.6650841,0 L37.5265412,0 C37.9258294,0 38.1448243,0.478849101 37.8918474,0.797108564 Z"/>
    <path fill="#B8CDD3"
          d="M33.25,30.7164179 C33.25,38.3912374 26.870098,44.6119403 19,44.6119403 C18.6740196,44.6119403 18.3499796,44.6015267 18.0298203,44.5797528 C10.611826,44.0941006 4.75,38.0731494 4.75,30.7164179 C4.75,23.3606331 10.611826,17.3387352 18.0298203,16.853083 C18.3499796,16.8313091 18.6740196,16.8208955 19,16.8208955 C26.870098,16.8208955 33.25,23.0425451 33.25,30.7164179 L33.25,30.7164179 Z"/>
    <path fill="#A5BABF"
          d="M33.25,30.7164179 C33.25,38.3912374 26.8785569,44.6119403 19.0188934,44.6119403 C18.6933452,44.6119403 18.3697348,44.6015267 18.05,44.5797528 C25.4581591,44.0941006 31.3122132,38.0731494 31.3122132,30.7164179 C31.3122132,23.3606331 25.4581591,17.3387352 18.05,16.853083 C18.3697348,16.8313091 18.6933452,16.8208955 19.0188934,16.8208955 C26.8785569,16.8208955 33.25,23.0425451 33.25,30.7164179 L33.25,30.7164179 Z"/>
    <path fill="#E8F4F9"
          d="M16.625,24.1343284 C15.83802,24.1343284 15.2,24.7681596 15.2,25.5498721 L15.2,35.8829637 C15.2,36.6647707 15.83802,37.2985075 16.625,37.2985075 C17.41198,37.2985075 18.05,36.6646763 18.05,35.8829637 L18.05,25.5498721 C18.05,24.7681596 17.41198,24.1343284 16.625,24.1343284 Z"/>
    <path fill="#E8F4F9"
          d="M21.375,24.1343284 C20.58802,24.1343284 19.95,24.7681596 19.95,25.5498721 L19.95,35.8829637 C19.95,36.6647707 20.58802,37.2985075 21.375,37.2985075 C22.16198,37.2985075 22.8,36.6646763 22.8,35.8829637 L22.8,25.5498721 C22.8,24.7681596 22.16198,24.1343284 21.375,24.1343284 Z"/>
    <path
      d="M23.75,10.238806 L7.95060752,10.238806 L5.71301856,7.54576642 L0.114108845,0.806841269 C-0.153493365,0.483715453 0.0795476743,0 0.502181423,0 L14.9882494,0 C15.1501932,0 15.3032498,0.0710487487 15.4059458,0.194654106 L23.75,10.238806 Z"/>
    <path
      d="M23.75,10.238806 L7.93920399,10.238806 L5.7,7.54576642 L14.4374411,7.54576642 C16.1193146,7.54576642 17.0442461,5.62063731 15.9789938,4.33884002 L12.3711677,0 L14.9819254,0 C15.1439861,0 15.2971532,0.0710487487 15.3999234,0.194654106 L23.75,10.238806 Z"/>
    <g transform="translate(6.650000, 18.283582)" fill="#D4E3E8">
      <path
        d="M19.6701465,2.35053155 C19.3459346,2.11688465 18.8893736,2.1840617 18.6502504,2.5007535 C18.4111272,2.81744529 18.479976,3.26364532 18.8039933,3.49729222 C19.7039874,4.14625861 20.4940957,4.92340299 21.1526327,5.80734652 C21.295387,5.99899589 21.5175898,6.10066412 21.7429043,6.10066412 C21.8916878,6.10066412 22.0419299,6.05629116 22.1714591,5.96412463 C22.4971295,5.73247309 22.5689929,5.28674815 22.3319118,4.96853608 C21.5856606,3.96686644 20.6901398,3.08605847 19.6701465,2.35053155 L19.6701465,2.35053155 Z"/>
      <path
        d="M5.16725945,2.24857827 C4.08804449,3.00367869 3.14429055,3.91888283 2.3621563,4.9686311 C2.1250752,5.28684317 2.19693858,5.7325681 2.52260906,5.96421965 C2.65213819,6.05638618 2.80228307,6.10075914 2.95116378,6.10075914 C3.17647835,6.10075914 3.3986811,5.9990909 3.54143543,5.80744153 C4.23157677,4.88102538 5.06427795,4.07357057 6.01649213,3.40731108 C6.34401024,3.178225 6.41937441,2.73307016 6.18482165,2.41305277 C5.95046339,2.09303537 5.4948748,2.01930215 5.16725945,2.24857827 L5.16725945,2.24857827 Z"/>
      <path
        d="M22.1714591,18.1359026 C21.8456913,17.904251 21.3896165,17.9745636 21.1525354,18.2926807 C20.4940957,19.1766242 19.7038902,19.9537686 18.8038961,20.602735 C18.4797815,20.8363819 18.4109327,21.2825819 18.6501531,21.5992737 C18.7931992,21.7886427 19.0138461,21.8887906 19.2376047,21.8887906 C19.3880413,21.8887906 19.5397421,21.8434675 19.6701465,21.7494957 C20.6901398,21.0140638 21.5856606,20.1331608 22.3320091,19.1313961 C22.5689929,18.8132791 22.4971295,18.3675541 22.1714591,18.1359026 L22.1714591,18.1359026 Z"/>
      <path
        d="M6.01658937,20.6928111 C5.0643752,20.0265517 4.23167402,19.2190968 3.54153268,18.2926807 C3.30454882,17.9746587 2.84847402,17.904156 2.5227063,18.1359976 C2.19703583,18.3676491 2.12517244,18.8132791 2.36225354,19.1314911 C3.14429055,20.1813344 4.08814173,21.0965385 5.16735669,21.851639 C5.29601063,21.9416201 5.44440512,21.9849479 5.59134094,21.9849479 C5.81860039,21.9849479 6.04255354,21.8814743 6.1849189,21.6871645 C6.41937441,21.3670521 6.34401024,20.9218972 6.01658937,20.6928111 L6.01658937,20.6928111 Z"/>
      <circle cx="12.3470827" cy="0.712342755" r="1"/>
      <circle cx="8.37563386" cy="1.39608538" r="1"/>
      <circle cx="16.3185315" cy="1.39599036" r="1"/>
      <circle cx="12.3470827" cy="23.3877795" r="1"/>
      <circle cx="8.37563386" cy="22.7040368" r="1"/>
      <circle cx="16.3185315" cy="22.7041319" r="1"/>
      <circle cx="23.9580276" cy="12.0503462" r="1"/>
      <circle cx="23.2582591" cy="8.16985022" r="1"/>
      <circle cx="23.2583563" cy="15.9308421" r="1"/>
      <circle cx="0.751113386" cy="12.0503462" r="1"/>
      <circle cx="1.45088189" cy="8.16985022" r="1"/>
      <circle cx="1.45078465" cy="15.9308421" r="1"/>
    </g>
    <path fill="#A5BABF"
          d="M31.575,9.50746269 L5.475,9.50746269 C5.07460667,9.50746269 4.75,9.83490945 4.75,10.238806 C4.75,10.6427025 5.07460667,10.9701493 5.475,10.9701493 L31.575,10.9701493 C31.9753933,10.9701493 32.3,10.6427025 32.3,10.238806 C32.3,9.83490945 31.9753933,9.50746269 31.575,9.50746269 Z"/>
  </svg>
)
