import { AnimationContext } from './AnimationContext'
import { AnimationStep } from './AnimationStep'

export class Animations<Game = any, Move = any, PlayerId = any> {

  getDuration(move: Move, context: AnimationContext<Game, Move, PlayerId>): number {
    switch (context.step) {
      case AnimationStep.BEFORE_MOVE:
        return this.getPreDuration ? this.getPreDuration(move, context) : 0
      case AnimationStep.AFTER_MOVE:
        return this.getPostDuration ? this.getPostDuration(move, context) : 0
      case AnimationStep.BEFORE_UNDO:
        return this.getUndoPreDuration ? this.getUndoPreDuration(move, context) : 0
      case AnimationStep.AFTER_UNDO:
        return this.getUndoPostDuration ? this.getUndoPostDuration(move, context) : 0
    }
  }

  getPreDuration?(move: Move, context: AnimationContext<Game, Move, PlayerId>): number

  getPostDuration?(move: Move, context: AnimationContext<Game, Move, PlayerId>): number

  getUndoPreDuration?(move: Move, context: AnimationContext<Game, Move, PlayerId>): number

  getUndoPostDuration?(move: Move, context: AnimationContext<Game, Move, PlayerId>): number
}
