import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import dayjs from 'dayjs'
import { Message } from '../../Chat'
import { PLATFORM_URI } from '../../index'

let apolloClient: ApolloClient<any> | undefined

export function getApolloClient() {
  if (!apolloClient) {
    apolloClient = initApolloClient()
  }
  return apolloClient
}

function initApolloClient() {
  const httpLink = new HttpLink({ uri: PLATFORM_URI + (process.env.REACT_APP_GRAPHQL_ENDPOINT ?? '/api/graphql'), fetch, credentials: 'include' })
  return new ApolloClient({
    link: ApolloLink.from([new RetryLink(), httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        ChatChannel: {
          keyFields: ['name'],
          fields: {
            messages: {
              keyArgs: false,
              merge(existing: Message[] = [], incoming: Message[] = []) {
                return [...incoming, ...existing]
                  .sort((m1, m2) => dayjs(m1.date).unix() - dayjs(m2.date).unix())
                  .filter((message, index, messages) => index === 0 || message.id !== messages[index - 1].id)
              }
            }
          }
        },
        Message: {
          keyFields: false
        }
      },
      possibleTypes: {
        GameNotification: ['MovePlayedNotification', 'MoveUndoneNotification']
      }
    })
  })
}