import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { GET_MESSAGE_AUTHOR } from '../api/Client/GameClientAPI'
import { GamePageState } from '../Store'
import { User } from '../User'

const query = new URLSearchParams(window.location.search)
export function useMessageAuthor(author: string) {

  const players = useSelector((state: GamePageState) => state.players)
  const player = players.find(p => p.userId === author)
  const skip = !!player || query.get('game') === null
  const { data } = useQuery<{ user: User }>(GET_MESSAGE_AUTHOR, { variables: { id: author }, skip: skip })
  const avatar = player?.avatar ?? data?.user.avatar
  const name = player?.name ?? data?.user.name
  return { avatar, name }
}
