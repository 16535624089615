export const PLATFORM_URI = process.env.REACT_APP_PLATFORM_URI ?? 'https://game-park.com'
export * from './Actions'
export * from './animations'
export * from './api'
export * from './Chat'
export * from './Deckbuilding'
export * from './EjectPlayer'
export * from './Failure'
export * from './GiveUp'
export * from './Hooks'
export * from './PlayerTime'
export * from './Rematch'
export * from './Sound'
export * from './Store'
export * from './Tutorial'
export * from './Types'
export * from './User'
export * from './Scoring'
