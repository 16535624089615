export const MOVE_UNDONE = 'MOVE_UNDONE'

export interface MoveUndone {
  type: typeof MOVE_UNDONE
  actionId: string
  delayed: boolean
}

export function moveUndone(actionId: string, delayed: boolean = false): MoveUndone {
  return {type: MOVE_UNDONE, actionId, delayed}
}