import { hasUnpredictableMoves, loopWithFuse, Rules, RulesCreator } from '@gamepark/rules-api'
import assert from 'assert'
import produce, { castDraft, Draft } from 'immer'
import isEqual from 'lodash/isEqual'
import uniqueId from 'lodash/uniqueId'
import { Reducer } from 'redux'
import {
  ACTION_FAILED,
  ActionFailed,
  ActionType,
  ANIMATION_COMPLETE,
  ANIMATION_PAUSED,
  ANIMATION_START,
  AnimationComplete,
  AnimationPaused,
  AnimationStart,
  CLEAR_FAILURES,
  ClearFailures,
  GAME_NOTIFICATIONS_RECEIVED,
  GAME_OVER,
  GAME_STATE_LOADED,
  GameNotificationsReceived,
  GameOver,
  GameStateLoaded,
  MOVE_UNDONE,
  MovePlayed,
  MoveUndone,
  SET_ANIMATIONS_SPEED,
  SetAnimationsSpeed
} from '../Actions'
import { PLAYER_QUIT, PlayerQuit } from '../Actions/PlayerQuit'
import { AnimationContext, Animations, AnimationStep } from '../animations'
import { Failure } from '../Failure'
import { GIVE_UP, GiveUp } from '../GiveUp'
import { MUTE_SOUNDS, MuteSounds, soundsMuted, UNMUTE_SOUNDS, UnmuteSounds } from '../Sound'
import { GameNotification, isMovePlayedNotification, isMoveUndoneNotification, MovePlayedNotification, MoveUndoneNotification, Player } from '../Types'
import { findLastIndex, findLastItem } from '../util/ArrayUtil'
import { DisplayedAction, getAnimatedMove } from './DisplayedAction'
import { GamePageState } from './GamePageState'

export type GameReducerContext<Game = any, Move = any, PlayerId = any> = {
  Rules: RulesCreator<Game, Move, PlayerId>
  animations?: Animations<Game, Move, PlayerId>
  version?: number
}

export type GameActions<Game = any, Move = any, PlayerId = any> = GameStateLoaded<Game, PlayerId> | GameNotificationsReceived<PlayerId>
  | MovePlayed<Move> | MoveUndone
  | AnimationStart | AnimationComplete | AnimationPaused | ActionFailed
  | ClearFailures | SetAnimationsSpeed | GiveUp | PlayerQuit | MuteSounds | UnmuteSounds | GameOver<PlayerId>

export function gameReducer<Game, Move, PlayerId>(context: GameReducerContext<Game, Move, PlayerId>): Reducer<GamePageState<Game, Move, PlayerId>, GameActions<Game, Move, PlayerId>> {
  const GameRules = context.Rules
  const animations = context.animations ?? new Animations()
  const initialState: GamePageState<Game, Move, PlayerId> = {
    players: [], pendingNotifications: [], nextNotificationIndex: 0, legalMoves: [], failures: [],
    animationIncrement: 0, animationsSpeed: 1, animationPaused: false,
    soundsMuted: soundsMuted(), clientTimeDelta: 0
  }
  return (state = initialState, event) => {
    switch (event.type) {
      case GAME_STATE_LOADED:
        return produce(state, draft => {
          if (event.serverTime) {
            draft.clientTimeDelta = approximateTimeDelta(event.serverTime, draft.clientTimeDelta)
          }
          draft.players = event.players as Player<Draft<PlayerId>>[]
          draft.options = event.options
          draft.playerId = event.playerId as Draft<PlayerId>
          draft.state = event.state as Draft<Game>
          if (event.playerId !== undefined) {
            draft.legalMoves = castDraft(new GameRules(event.state).getLegalMoves(event.playerId))
          }
          draft.setup = event.setup as Draft<Game>
          draft.gameOver = !!event.endDate
          draft.gameMode = event.mode
          draft.tournament = event.tournament
          draft.nextNotificationIndex = event.notifications.length
          if (!event.endDate) {
            draft.client = { player: event.playerId as Draft<PlayerId> }
          }
          setActionsFromPastNotifications(draft, event.notifications)
        })
      case GAME_NOTIFICATIONS_RECEIVED:
        return produce(state, draft => {
          if (event.serverTime) {
            draft.clientTimeDelta = approximateTimeDelta(event.serverTime, draft.clientTimeDelta)
          }
          draft.players.forEach(player => {
            const newPlayerTime = event.players.find(p => p.id === player.id)?.time
            player.time = newPlayerTime || player.time
          })
          if (event.notificationsIndex == 0 && !draft.actions) {
            setActionsFromPastNotifications(draft, event.notifications)
          }
          for (let i = Math.max(draft.nextNotificationIndex - event.notificationsIndex, 0); i < event.notifications.length; i++) {
            draft.pendingNotifications.push({ ...event.notifications[i] as Draft<GameNotification<PlayerId>>, index: i + event.notificationsIndex })
          }
          draft.pendingNotifications.sort((n1, n2) => n1.index - n2.index)
          if (draft.state) {
            applyPendingNotifications(draft)
          }
        })
      case ActionType.MOVE_PLAYED:
        return produce(state, draft => {
          if (!draft.state) throw new Error('Cannot play move before game state is loaded')
          if (!draft.actions) throw new Error('Cannot play move before game history is loaded')
          if (state.playerId === undefined && !event.local) throw new Error('Spectators cannot play')

          const action: DisplayedAction = {
            id: event.local ? 'local-' + uniqueId() : undefined,
            playerId: state.playerId,
            move: event.move,
            consequences: [],
            played: 0,
            pending: !event.local,
            local: event.local
          }

          const rules = getGameAfterAnimations(draft as GamePageState<Game, Move, PlayerId>, GameRules)
          const isUnpredictable = (move: Move): boolean => hasUnpredictableMoves(rules) && rules.isUnpredictableMove(move, action.playerId)
          if (!event.local && (event.delayed || isUnpredictable(event.move))) {
            action.delayed = true
          } else {

            action.consequences = []
            const pendingMoves: Move[] = rules.play(JSON.parse(JSON.stringify(event.move)), { local: event.local })
            loopWithFuse(() => {
              const move = pendingMoves.shift()
              if (!move || isUnpredictable(move)) return false
              action.consequences.push(move)
              pendingMoves.unshift(...rules.play(JSON.parse(JSON.stringify(move)), { local: event.local }))
              return true
            }, { errorFn: () => new Error(`Infinite loop detected while applying move consequences: ${JSON.stringify(action)})`) })

            if (event.skipAnimation) {
              playNextMove(action, draft)
            } else {
              animatePlayedAction(action, draft)
            }
          }
          if (!event.transient) {
            draft.actions.push(action)
          }
        })
      case MOVE_UNDONE:
        return produce(state, draft => {
          if (!state.actions || !draft.actions || !state.setup || !state.state) {
            return console.error('Cannot undo before actions history is loaded')
          }
          const action = findLastItem(draft.actions, action => action.id === event.actionId)
          if (!action) return console.error(`Could not find action to undo with this id: ${event.actionId}`)
          if (!action.local && state.playerId === undefined) {
            return console.error('Spectators cannot undo moves')
          }
          action.cancelled = true
          if (!action.local) {
            action.cancelPending = true
          }
          if (!action.local && (event.delayed || !new GameRules(state.state, state.client).isTurnToPlay(state.playerId!))) {
            action.delayed = true
          } else {
            animateUndoneAction(action, draft)
          }
        })
      case ACTION_FAILED:
        return produce(state, draft => {
          if (draft.actions) {
            switch (event.reason) {
              case Failure.UNDO_FORBIDDEN:
                for (const action of draft.actions) {
                  if (action.cancelPending) {
                    action.cancelled = false
                    action.cancelPending = false
                  }
                }
                break
              default:
                draft.actions = draft.actions.filter(action => !action.pending)
                const rules = new GameRules(JSON.parse(JSON.stringify(draft.setup)), state.client)
                replayActions(rules, draft.actions as DisplayedAction<Move, PlayerId>[])
                draft.state = rules.game as Draft<Game>
            }
          }
          draft.failures.push(event.reason)
        })
      case CLEAR_FAILURES:
        return produce(state, draft => {
          draft.failures = []
        })
      case ANIMATION_START:
        return produce(state, draft => {
          if (!draft.actions) return
          const action = draft.actions.find(action => action.id === event.actionId)
          if (action) {
            animateAction(action, draft)
          }
        })
      case ANIMATION_COMPLETE:
        return produce(state, draft => {
          if (draft.actions) {
            const action = draft.actions.find(action => action.animation?.id === event.animationId)
            if (!action) {
              return console.error('ANIMATION_COMPLETE event received but not animation found with provided id')
            }
            const step = action.animation?.step
            delete action.animation
            if (step === AnimationStep.BEFORE_MOVE) {
              playNextMove(action, draft)
            } else if (step === AnimationStep.BEFORE_UNDO) {
              undoNextMove(action, draft)
            } else {
              refreshLegalMoves(draft)
            }
          }
        })
      case ANIMATION_PAUSED:
        return produce(state, draft => {
          draft.animationPaused = event.pause
        })
      case SET_ANIMATIONS_SPEED:
        return { ...state, animationsSpeed: event.speed }
      case GIVE_UP:
        return { ...state, playerId: undefined }
      case MUTE_SOUNDS:
        return { ...state, soundsMuted: true }
      case UNMUTE_SOUNDS:
        return { ...state, soundsMuted: false }
      case PLAYER_QUIT:
        return produce(state, draft => {
          const player = draft.players.find(p => p.id === event.playerId)
          if (player) {
            player.quit = true
            player.quitReason = event.reason
          }
          if (draft.playerId === event.playerId) {
            delete draft.playerId
          }
        })
      case GAME_OVER:
        return {
          ...state, gameOver: true, players: state.players.map(player => {
            const p = event.players.find(p => p.id === player.id)
            if (!p) return player
            else return ({ ...player, time: p.time ?? player.time, gamePointsDelta: p.gamePointsDelta, tournamentPoints: p.tournamentPoints })
          })
        }
    }
    return state
  }

  function playNextMove(action: DisplayedAction<Draft<Move>, Draft<PlayerId>>, draft: Draft<GamePageState<Game, Move, PlayerId>>) {
    const move = action.played === 0 ? action.move : action.consequences[action.played - 1]
    const rules = new GameRules(draft.state as Game, draft.client as { player?: PlayerId })
    rules.play(JSON.parse(JSON.stringify(move)), { local: action.local })
    action.played++
    animatePlayedAction(action, draft, AnimationStep.AFTER_MOVE)
  }

  function undoNextMove(action: DisplayedAction<Draft<Move>, Draft<PlayerId>>, draft: Draft<GamePageState<Game, Move, PlayerId>>) {
    const rules = new GameRules(JSON.parse(JSON.stringify(draft.setup)), draft.client as {
      player?: PlayerId
    }) as Rules<Draft<Game>, Draft<Move>, Draft<PlayerId>>
    action.played--
    const actionsPlayed = draft.actions!.filter(action => action.played > 0)
      .map(action => ({
        ...action,
        consequences: action.consequences.slice(0, action.played - 1)
      }))
    replayActions(rules, actionsPlayed)
    draft.state = rules.game
    animateUndoneAction(action, draft, AnimationStep.AFTER_UNDO)
  }

  function setActionsFromPastNotifications(draft: Draft<GamePageState<Game, Move, PlayerId>>, notifications: GameNotification<PlayerId>[]) {
    draft.actions = []
    for (let i = 0; i < draft.nextNotificationIndex; i++) {
      const notification = notifications[i]
      if (isMovePlayedNotification(notification)) {
        draft.actions.push({
          id: notification.actionId ?? undefined,
          playerId: notification.playerId as Draft<PlayerId>, move: notification.moveView as Draft<Move>,
          consequences: notification.consequences as Draft<Move>[],
          played: 1 + notification.consequences.length
        })
      } else if (isMoveUndoneNotification(notification)) {
        const index = notification.actionId ? findLastIndex(draft.actions, action => action.id === notification.actionId) : notification.moveUndone
        draft.actions.splice(index, 1)
      }
    }
  }

  function applyPendingNotifications(draft: Draft<GamePageState<Game, Move, PlayerId>>) {
    if (!draft.actions) {
      return
    }
    while (draft.pendingNotifications.length && draft.pendingNotifications[0].index <= draft.nextNotificationIndex) {
      const notification = draft.pendingNotifications.shift()!
      if (notification.index < draft.nextNotificationIndex) {
        console.info('A notification was received twice: ' + JSON.stringify(notification))
      } else {
        draft.nextNotificationIndex++
        applyNotification(draft, notification)
      }
    }
  }

  function applyNotification(draft: Draft<GamePageState<Game, Move, PlayerId>>, notification: GameNotification<Draft<PlayerId>>) {
    if (isMovePlayedNotification<Draft<Move>, Draft<PlayerId>>(notification)) {
      applyMovePlayedNotification(draft, notification)
    } else if (isMoveUndoneNotification(notification)) {
      applyMoveUndoneNotification(draft, notification)
    }
  }

  function applyMovePlayedNotification(draft: Draft<GamePageState<Game, Move, PlayerId>>, notification: MovePlayedNotification<Draft<Move>, Draft<PlayerId>>) {
    assert(draft.actions !== undefined, 'Cannot apply a notification before action history is loaded')
    let action
    if (notification.playerId == draft.playerId) {
      action = draft.actions.find(action => action.pending && action.playerId == notification.playerId)
    }
    if (action) {
      action.id = notification.actionId ?? undefined
      action.pending = false
      if (action.delayed) {
        action.move = notification.moveView as Draft<Move>
        action.consequences = notification.consequences as Draft<Move>[]
        delete action.delayed
        animateAction(action, draft)
      } else {
        // Help for developer: if action is not delayed, its data must usually be equal to the notification's data
        if (!isEqual(JSON.parse(JSON.stringify(action.move)), JSON.parse(JSON.stringify(notification.moveView)))) {
          const rules = new GameRules(draft.state as Game)
          if (hasUnpredictableMoves(rules) && rules.canIgnoreServerDifference
            && rules.canIgnoreServerDifference(action.move as Move, notification.moveView as Move)) {
            action.move = notification.moveView
          } else {
            console.error('An action was played and executed immediately:', JSON.parse(JSON.stringify(action.move)), ', but the notifications does not contain the same data:',
              JSON.parse(JSON.stringify(notification.moveView)), '(use the "delayed" parameter to postpone execution if the outcome cannot be anticipated).')
          }
        }
        if (action.consequences.length > notification.consequences.length) {
          console.error('The server returned less consequences that what was played locally:', action.consequences, notification.consequences)
        } else {
          for (let i = 0; i < action.consequences.length; i++) {
            if (!isEqual(JSON.parse(JSON.stringify(action.consequences[i])), JSON.parse(JSON.stringify(notification.consequences[i])))) {
              const rules = new GameRules(draft.state as Game)
              if (hasUnpredictableMoves(rules) && rules.canIgnoreServerDifference
                && rules.canIgnoreServerDifference(action.consequences[i] as Move, notification.consequences[i] as Move)) {
                action.consequences[i] = notification.consequences[i]
              } else {
                console.error(`Consequence #${i} was played on the client, but the server returned a different consequence:`,
                  JSON.parse(JSON.stringify(action.consequences[i])), notification.consequences[i])
              }
            }
          }
          action.consequences.push(...notification.consequences.slice(action.consequences.length))
        }
      }
    } else {
      draft.actions!.splice(findLastIndex(draft.actions!, action => !action.pending) + 1, 0, {
        id: notification.actionId ?? undefined,
        playerId: notification.playerId as Draft<PlayerId>,
        move: notification.moveView as Draft<Move>,
        consequences: notification.consequences as Draft<Move>[],
        played: 0
      })
    }
  }

  function applyMoveUndoneNotification(draft: Draft<GamePageState<Game, Move, PlayerId>>, notification: MoveUndoneNotification<Draft<PlayerId>>) {
    assert(draft.actions !== undefined, 'Cannot apply a notification before action history is loaded')
    const index = findLastIndex(draft.actions, action => action.id === notification.actionId)
    if (index === -1) return console.error(`Received move undone notification, but action ${notification.actionId} is unknown`)
    const cancelledAction = draft.actions[index]
    if (!cancelledAction.played && !cancelledAction.animation) {
      draft.actions.splice(index, 1)
    } else {
      cancelledAction.cancelled = true
      delete cancelledAction.cancelPending
      delete cancelledAction.delayed
    }
  }

  function animateAction(action: DisplayedAction<Draft<Move>, Draft<PlayerId>>, draft: Draft<GamePageState<Game, Move, PlayerId>>) {
    if (!action.cancelled) {
      animatePlayedAction(action, draft)
    } else {
      animateUndoneAction(action, draft)
    }
  }

  function animatePlayedAction(action: DisplayedAction<Draft<Move>, Draft<PlayerId>>, draft: Draft<GamePageState<Game, Move, PlayerId>>,
                               step = AnimationStep.BEFORE_MOVE) {
    if (action.activePlayers === undefined) {
      const rules = new GameRules(draft.state as Game, draft.client as { player?: PlayerId })
      action.activePlayers = draft.players.map(p => p.id).filter(p => rules.isTurnToPlay(p as PlayerId))
    }
    const move = getAnimatedMove(action, step) as Move
    const duration = animations.getDuration(move, getAnimationContext(action, draft, step)) / draft.animationsSpeed
    if (duration > 0) {
      action.animation = { id: draft.animationIncrement++, duration, step }
    } else if (step === AnimationStep.BEFORE_MOVE) {
      playNextMove(action, draft)
    } else if (action.played <= action.consequences.length && !draft.animationPaused) {
      animatePlayedAction(action, draft)
    } else {
      refreshLegalMoves(draft)
    }
  }

  function refreshLegalMoves(draft: Draft<GamePageState<Game, Move, PlayerId>>) {
    if (draft.playerId !== undefined) {
      const rules = new GameRules(draft.state as Game, draft.client as { player?: PlayerId })
      draft.legalMoves = castDraft(rules.getLegalMoves(draft.playerId as PlayerId))
    }
  }

  function animateUndoneAction(action: DisplayedAction<Draft<Move>, Draft<PlayerId>>, draft: Draft<GamePageState<Game, Move, PlayerId>>,
                               step = AnimationStep.BEFORE_UNDO) {
    const move = getAnimatedMove(action, step) as Move
    const duration = animations.getDuration(move, getAnimationContext(action, draft, step)) / draft.animationsSpeed
    if (duration > 0) {
      action.animation = { id: draft.animationIncrement++, duration, step }
    } else if (step === AnimationStep.BEFORE_UNDO) {
      undoNextMove(action, draft)
    } else if (action.played > 0) {
      animateUndoneAction(action, draft)
    } else {
      refreshLegalMoves(draft)
      if (!action.cancelPending) {
        draft.actions!.splice(draft.actions!.indexOf(action), 1)
      }
    }
  }

  function getAnimationContext(action: DisplayedAction<Draft<Move>, Draft<PlayerId>>,
                               draft: Draft<GamePageState<Game, Move, PlayerId>>, step: AnimationStep): AnimationContext<Game, Move, PlayerId> {
    return {
      ...context,
      action: action as DisplayedAction<Move, PlayerId>,
      game: draft.state as Game,
      playerId: draft.playerId as PlayerId,
      step
    }
  }
}

export function getGameAfterAnimations<Game, Move, PlayerId>(state: GamePageState<Game, Move, PlayerId>, Rules: RulesCreator<Game, Move, PlayerId>) {
  if (!state.actions || !state.setup) {
    throw new Error('Cannot play move before game history is loaded')
  }
  if (state.actions.some(action => action.animation || action.cancelled)) {
    // We need to avoid side effects of other actions that we might currently be animating => let's get the game state as if every animation is over
    const rules = new Rules(JSON.parse(JSON.stringify(state.setup)), state.client)
    replayActions(rules, state.actions.filter(action => !action.delayed && !action.cancelled) as DisplayedAction<Move, PlayerId>[])
    return rules
  } else {
    return new Rules(JSON.parse(JSON.stringify(state.state)), { player: state.playerId as PlayerId | undefined })
  }
}

const estimatedLatency = 100

function approximateTimeDelta(serverTime: number, clientTimeDelta?: number) {
  return Math.min(serverTime + estimatedLatency - new Date().getTime(), clientTimeDelta || Infinity)
}

export function replayActions<Game, Move, PlayerId>(rules: Rules<Game, Move, PlayerId>, actions: DisplayedAction<Move, PlayerId>[]) {
  actions.forEach(action => replayAction(rules, action))
}

export function replayAction<Game, Move, PlayerId>(rules: Rules<Game, Move, PlayerId>, action: DisplayedAction<Move, PlayerId>) {
  rules.play(JSON.parse(JSON.stringify(action.move)), { local: action.local })
  action.consequences.forEach(move => rules.play(JSON.parse(JSON.stringify(move)), { local: action.local }))
}