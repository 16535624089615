import { SVGProps } from 'react'

export const MedalGold = (props: SVGProps<SVGSVGElement>) => (
  <svg width="38px" height="49px" viewBox="0 0 38 49" {...props}>
    <g transform="translate(11.000000, 9.000000)" fill="#FB963F">
      <polygon transform="translate(15.884360, 1.908896) rotate(-82.007686) translate(-15.884360, -1.908896) "
               points="14.2807829 1.09235193 17.4378599 1.23038092 17.4879381 2.72543994 14.3308611 2.58741095"/>
      <polygon transform="translate(1.129563, 2.003374) rotate(-7.992314) translate(-1.129563, -2.003374) "
               points="0.308116797 0.287660139 1.80317582 0.337738381 1.95101013 3.7190882 0.455951106 3.66900996"/>
    </g>
    <path fill="#FFE8A7"
          d="M37,30.9995317 C37,35.9646936 34.923921,40.4662157 31.5712168,43.7257448 C28.2213989,46.9834005 23.598804,49 18.5,49 C18.1450078,49 17.7929017,48.9906335 17.4427197,48.970964 C12.7643266,48.7133862 8.54578783,46.7567321 5.42878315,43.7257448 C2.07607904,40.4662157 0,35.9646936 0,30.9995317 C0,26.0371797 2.075117,21.5375309 5.424935,18.2780018 C8.54193968,15.2451411 12.7614405,13.2866138 17.4427197,13.029036 C17.7929017,13.0093665 18.1450078,13 18.5,13 C23.600728,13 28.225247,15.0184727 31.575065,18.2780018 C34.924883,21.5375309 37,26.0371797 37,30.9995317 Z"/>
    <g transform="translate(6.000000, 13.000000)" fill="#FFE26F">
      <path
        d="M26.4442553,5.32987013 L25.3383587,6.39303896 L13.2299088,18.0345974 L13.2250456,18.0383377 L0.00583586626,5.32987013 C3.15720365,2.30212987 7.42322188,0.346909091 12.1561094,0.0897662338 C12.510152,0.0701298701 12.8661398,0.0607792208 13.2250456,0.0607792208 C18.3820061,0.0607792208 23.0575076,2.07584416 26.4442553,5.32987013 Z"/>
      <path
        d="M26.4403647,30.7346494 C23.053617,33.9868052 18.3800608,36 13.2250456,36 C12.8661398,36 12.510152,35.9906494 12.1561094,35.971013 C7.42613982,35.7138701 3.16109422,33.7605195 0.00972644377,30.7346494 L13.221155,18.0345974 L13.2250456,18.0299221 L26.4403647,30.7346494 Z"/>
      <path
        d="M31.928997,18.0299221 C31.928997,22.9867013 29.8300304,27.4806234 26.4403647,30.7346494 C23.053617,33.9868052 18.3800608,36 13.2250456,36 C12.8661398,36 12.510152,35.9906494 12.1561094,35.971013 C21.9875988,35.438026 29.7891793,27.6096623 29.7891793,18.0299221 C29.7891793,8.45111688 21.9875988,0.622753247 12.1561094,0.0897662338 C12.510152,0.0701298701 12.8661398,0.0607792208 13.2250456,0.0607792208 C18.3820061,0.0607792208 23.0575076,2.07584416 26.4442553,5.32987013 C29.831003,8.5838961 31.928997,13.0759481 31.928997,18.0299221 Z"/>
    </g>
    <path fill="#FFC54B"
          d="M30.9161106,43 L32,44.0079787 C28.6806482,47.0913121 24.1000953,49 19.0476644,49 C18.6959009,49 18.3469971,48.9911348 18,48.9725177 C23.1687321,48.7012411 27.7645377,46.4397163 30.9161106,43 Z"/>
    <path fill="#FFC54B"
          d="M18,13.027466 C18.3469027,13.00886 18.6957114,13 19.0473792,13 C24.1003404,13 28.6815521,14.9093325 32,17.9926167 L30.9164057,19 C27.7656909,15.5596574 23.1692308,13.2985824 18,13.027466 Z"/>
    <path
      d="M37.8856989,0.700665399 L29.9617083,9 L14,9 L22.4437437,0.157414449 C22.5385164,0.0573193916 22.6801765,0 22.8308151,0 L37.4996251,0 C37.9216128,0 38.1530575,0.420912548 37.8856989,0.700665399 Z"/>
    <path d="M37.8861551,0.700665399 L29.993792,9 L27,9 L35.5421938,0 L37.5016223,0 C37.9219256,0 38.1524466,0.420912548 37.8861551,0.700665399 Z"/>
    <path fill="#FFC54B"
          d="M34,30.5 C34,38.5086865 27.5081699,45 19.5,45 C19.1683007,45 18.8385757,44.9891334 18.5127996,44.9664123 C10.964665,44.4596335 5,38.1767611 5,30.5 C5,22.8242267 10.964665,16.5403665 18.5127996,16.0335877 C18.8385757,16.0108666 19.1683007,16 19.5,16 C27.5081699,16 34,22.4923014 34,30.5 L34,30.5 Z"/>
    <path fill="#FEAC38"
          d="M33,30.5 C33,38.5086865 27.1315655,45 19.8924018,45 C19.5925548,45 19.2944926,44.9891334 19,44.9664123 C25.8233044,44.4596335 31.2151963,38.1767611 31.2151963,30.5 C31.2151963,22.8242267 25.8233044,16.5403665 19,16.0335877 C19.2944926,16.0108666 19.5925548,16 19.8924018,16 C27.1315655,16 33,22.4923014 33,30.5 L33,30.5 Z"/>
    <path fill="#FFE8A7"
          d="M19,24 C17.8954667,24 17,24.6259263 17,25.3978896 L17,35.6021104 C17,36.374167 17.8954667,37 19,37 C20.1045333,37 21,36.3740737 21,35.6021104 L21,25.3978896 C21,24.6259263 20.1045333,24 19,24 L19,24 Z"/>
    <path
      d="M24,9 L8.03429812,9 L5.77315559,6.63279468 L0.115309991,0.709220532 C-0.155109084,0.425190114 0.0803850183,0 0.507467543,0 L15.1460205,0 C15.3096689,0 15.4643366,0.0624524715 15.5681137,0.171102662 L24,9 Z"/>
    <path
      d="M24,9 L8.2330012,9 L6,6.63279468 L14.7132377,6.63279468 C16.3904522,6.63279468 17.3128216,4.94058935 16.2505201,3.81387833 L12.6526881,0 L15.2562137,0 C15.4178255,0 15.5705683,0.0624524715 15.6730538,0.171102662 L24,9 Z"/>
    <g transform="translate(7.000000, 18.000000)" fill="#FFE26F">
      <path
        d="M19.9090551,2.43484252 C19.5809055,2.19281496 19.1187992,2.26240157 18.8767717,2.59045276 C18.6347441,2.91850394 18.7044291,3.38070866 19.0323819,3.62273622 C19.9433071,4.29498031 20.7430118,5.1 21.4095472,6.01564961 C21.5540354,6.21417323 21.778937,6.31948819 22.0069882,6.31948819 C22.1575787,6.31948819 22.3096457,6.27352362 22.440748,6.17805118 C22.770374,5.93809055 22.8431102,5.47637795 22.6031496,5.14675197 C21.8478346,4.10915354 20.941437,3.19675197 19.9090551,2.43484252 L19.9090551,2.43484252 Z"/>
      <path
        d="M5.23001969,2.32923228 C4.13769685,3.11141732 3.18248031,4.05944882 2.39084646,5.14685039 C2.15088583,5.47647638 2.22362205,5.93818898 2.55324803,6.17814961 C2.68435039,6.27362205 2.8363189,6.31958661 2.98700787,6.31958661 C3.21505906,6.31958661 3.43996063,6.21427165 3.58444882,6.01574803 C4.28297244,5.05610236 5.1257874,4.21968504 6.08956693,3.52952756 C6.42106299,3.29222441 6.49734252,2.83110236 6.25994094,2.4996063 C6.02273622,2.16811024 5.56161417,2.09173228 5.23001969,2.32923228 L5.23001969,2.32923228 Z"/>
      <path
        d="M22.440748,18.7864173 C22.1110236,18.5464567 21.6494094,18.6192913 21.4094488,18.9488189 C20.7430118,19.8644685 19.9432087,20.6694882 19.0322835,21.3417323 C18.7042323,21.5837598 18.6345472,22.0459646 18.8766732,22.3740157 C19.0214567,22.5701772 19.2447835,22.6739173 19.4712598,22.6739173 C19.6235236,22.6739173 19.7770669,22.6269685 19.9090551,22.529626 C20.941437,21.767815 21.8478346,20.855315 22.603248,19.8176181 C22.8431102,19.4880906 22.770374,19.026378 22.440748,18.7864173 L22.440748,18.7864173 Z"/>
      <path
        d="M6.08966535,21.4350394 C5.12588583,20.7448819 4.28307087,19.9084646 3.58454724,18.9488189 C3.34468504,18.6193898 2.88307087,18.5463583 2.55334646,18.7865157 C2.22372047,19.0264764 2.15098425,19.4880906 2.39094488,19.8177165 C3.18248031,20.9052165 4.13779528,21.853248 5.23011811,22.6354331 C5.36033465,22.7286417 5.5105315,22.7735236 5.65925197,22.7735236 C5.88927165,22.7735236 6.11594488,22.6663386 6.26003937,22.4650591 C6.49734252,22.1334646 6.42106299,21.6723425 6.08966535,21.4350394 L6.08966535,21.4350394 Z"/>
      <circle cx="12.4970472" cy="0.737893701" r="1"/>
      <circle cx="8.4773622" cy="1.44616142" r="1"/>
      <circle cx="16.5167323" cy="1.44606299" r="1"/>
      <circle cx="12.4970472" cy="24.2266732" r="1"/>
      <circle cx="8.4773622" cy="23.5184055" r="1"/>
      <circle cx="16.5167323" cy="23.5185039" r="1"/>
      <circle cx="24.2490157" cy="12.4825787" r="1"/>
      <circle cx="23.540748" cy="8.4628937" r="1"/>
      <circle cx="23.5408465" cy="16.5022638" r="1"/>
      <circle cx="0.76023622" cy="12.4825787" r="1"/>
      <circle cx="1.46850394" cy="8.4628937" r="1"/>
      <circle cx="1.46840551" cy="16.5022638" r="1"/>
    </g>
    <path fill="#FEAC38"
          d="M31.2894737,9 L5.71052632,9 C5.31812632,9 5,9.22386667 5,9.5 C5,9.77613333 5.31812632,10 5.71052632,10 L31.2894737,10 C31.6818737,10 32,9.77613333 32,9.5 C32,9.22386667 31.6818737,9 31.2894737,9 Z"/>
  </svg>
)
