import { PlayerQuitReason } from '../Types'

export const PLAYER_QUIT = 'PLAYER_QUIT'

export interface PlayerQuit<PlayerId = any> {
  type: typeof PLAYER_QUIT
  playerId: PlayerId,
  reason: PlayerQuitReason
}

export function playerQuit<PlayerId>(data: { playerId: PlayerId, reason: PlayerQuitReason }): PlayerQuit<PlayerId> {
  return { type: PLAYER_QUIT, ...data }
}