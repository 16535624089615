import { Action } from '@gamepark/rules-api'
import { GameNotification } from './GameNotification'

export type MoveUndoneNotification<PlayerId> = GameNotification<PlayerId> & {
  actionId: string | null
  moveUndone: number
}

export function moveUndoneNotification<PlayerId>(action: Action<any, PlayerId>, moveUndone: number): MoveUndoneNotification<PlayerId> {
  return {playerId: action.playerId, actionId: action.id ?? null, moveUndone}
}

export function isMoveUndoneNotification<PlayerId>(notification: GameNotification<PlayerId>): notification is MoveUndoneNotification<PlayerId> {
  return (notification as MoveUndoneNotification<PlayerId>).moveUndone != undefined
}
