import { useMutation, useQuery } from '@apollo/client'
import { CAN_REMATCH, CanRematchData, REMATCH } from '../Rematch'
import { useChannel, useEvent } from '@harelpls/use-pusher'

export function useRematch() {
  const query = new URLSearchParams(window.location.search)
  const gameId = query.get('game')
  const canRematchResult = useQuery<{ game: CanRematchData }>(CAN_REMATCH, { variables: { id: gameId } })
  const game = canRematchResult.data?.game
  const [rematch] = useMutation(REMATCH)
  const channel = useChannel('rematch=' + gameId)
  useEvent(channel, 'rematch', () => canRematchResult.refetch())
  useEvent(channel, 'pusher:subscription_succeeded', () => canRematchResult.refetch())
  return { game, rematch: () => rematch({ variables: { gameId } }).catch(() => canRematchResult.refetch()) }
}